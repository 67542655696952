import { Component, ElementRef, OnInit, Renderer2, ViewChild  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { eContentType } from 'src/app/core/enums/eContentType';
import { eTypeMessage } from 'src/app/core/enums/eTypeMessage';
import { iAvatar } from 'src/app/core/models/iAvatar';
import { iFlag } from 'src/app/core/models/iFlag';
import { iHistory } from 'src/app/core/models/iHistory';
import { iMessageLex } from 'src/app/core/models/iMessageLex';
import { iSettings } from 'src/app/core/models/iSettings';
import { AwsLexService } from 'src/app/core/services/aws-lex.service';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { v4 as uuidv4 } from 'uuid';
import { tap } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [
    NgIf, 
    NgFor,
    NgClass, 
    TranslateModule, 
    MatSelectModule, 
    MatCardModule, 
    MatOptionModule, 
    ReactiveFormsModule, 
    FormsModule,    
    RouterModule, 
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: Window, useValue: window }]
})
export class AppComponent implements OnInit {
  SPANISH_LANGUAGE_CODE = [
    'es',
    'es-419',
    'es-AR',
    'es-BO',
    'es-CL',
    'es-CO',
    'es-CR',
    'es-DO',
    'es-EC',
    'es-ES',
    'es-ES',
    'es-GT',
    'es-HN',
    'es-MX',
    'es-NI',
    'es-PA',
    'es-PE',
    'es-PR',
    'es-PY',
    'es-SV',
    'es-UY',
    'es-VE',
  ];

  ENGLISH_LANGUAGE_CODE = ['en', 'en-AU', 'en-BZ', 'en-CA', 'en-CB', 'en-GB', 'en-IE', 'en-JM', 'en-NZ', 'en-PH', 'en-TT', 'en-US', 'en-ZA', 'en-ZW'];

  PORTUGUES_LANGUAGE_CODE = ['pt', 'pt-BR', 'pt-PT'];
  /**Logo para el bot. */
  LOGO_BOT: string = '';

  AVATAR_IMG: string = '';
  /**Mostrar o no el mensaje de bienvenida. */
  SHOW_MESSAGE: boolean = false;
  /**Bandera de la primera carga para el mensaje de bienvenida. */
  private FIRST_CHARGE: boolean = true;

  /**Index de la imagen en el slider. */
  private SLIDE_INDEX: number = 1;
  /**Numerador del slider en página actual. */
  private NUMBER_SLIDER: number = 1;
  /**Muestra u oculta el chat. */
  SHOW_CHAT: boolean = false;
  /**Permite o no el envío de mensajes personalizados por el usuario. */
  private SEND_MESSAGE: boolean = false;
  /**Indicador para finalizar las interacciones generales. */
  private FINISH_ACTIONS: boolean = false;
  /**Cantidad de apartados con botones a eliminar. */
  private POR_ELIMINAR: number = 0;
  /**Cantidad de divs de puntos suspensivos a eliminar. */
  private POR_ELIMINAR_DOTS: number = 0;
  /**Animación de "Escribiendo...". */
  DOTS_GIF: string = '';
  /**Variable que se encargará de mostrar las variaciones del tiempo. */
  HORA: string = '';
  /** */
  MENSAJE: string = '';
  /**Placeholder para el input. */
  PLACEHOLDER: string = 'Seleccione una opción...';
  /**Estatus del input. */
  DISABLED_INPUT: boolean = true;
  /**Arreglo que alojará el historial del usuario. */
  HISTORIAL_BOT: iHistory[] = [];
  /**Estatus para el borrado del historial. */
  BORRA_HISTORIAL: boolean = false;
  /**Objeto para el envío de la respuesta del usuario. */
  // LEX_MESSAGE!: iMessageLex;

  FORM!: FormGroup;
  ELIMINAR_SALUDO: number = 0;
  ELIMINAR_DATOS_INICIALES: number = 0;
  SHOW_BUTTON: boolean = false;
  ALL_COUNTRIES: iFlag[] = [];
  ALL_AVATARS: iAvatar[] = [];
  ALL_AGES: any = [];
  TIME: number = 2000;

  SETTINGS_CHATBOT!: iSettings;
  LAST_BODY: any;

  selectedCountryCode = '';
  countryCodes: string[] = [];
  flagCode = '';

  backMenuFlag = 0;

  GIF_CONTROL_ID = 1;

  ALERT_VIEW: boolean = true;
  STARTER_VIEW: boolean = false;
  LANGUAGE_VIEW: boolean = false;
  INCHAT_CONTENT: boolean = false;
  userLang: any = 'es_419';

  DOTS_VALIDATOR: boolean = false;

  LOGO_HEADER: string = '';

  translatedDescription: string = '';
  sanitizedDescription: any;

  selectedCountry: any;

  @ViewChild('botOrganon') MESSAGES!: ElementRef;
  constructor(
    private renderer: Renderer2,
    private window: Window,
    private readonly awsLexService: AwsLexService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {
    this.userLang = this.getLanguageCode();
    localStorage.setItem('lang', this.userLang);
    this.translate.setDefaultLang(localStorage.getItem('lang') || 'es_419');
  }

  getPrivacity(): void {
    this.translate
      .get('ALERT_DESCRIPTION_2')
      .pipe(
        tap((translation: string) => {
          this.translatedDescription = translation;
          this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(this.translatedDescription);
        })
      )
      .subscribe();
  }

  getLanguageCode() {
    const esLangCode = this.SPANISH_LANGUAGE_CODE.map((element) => element.toLowerCase());
    const enLangCode = this.ENGLISH_LANGUAGE_CODE.map((element) => element.toLowerCase());
    const ptLangCode = this.PORTUGUES_LANGUAGE_CODE.map((element) => element.toLowerCase());
    const navigatorLanguage = navigator.language.toLowerCase();

    if (esLangCode.includes(navigatorLanguage)) {
      return 'es_419';
    }
    if (enLangCode.includes(navigatorLanguage)) {
      return 'en_US';
    }
    if (ptLangCode.includes(navigatorLanguage)) {
      return 'pt_BR';
    }

    return 'es_419';
  }

  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
  }

  /**
   * Carga un arreglo de banderas de países predefinidos.
   * @returns Arreglo de banderas.
   * @JuanUicab-Lumston
   */
  loadCountries(): iFlag[] {
    const _flags: iFlag[] = [];
    _flags.push({ image: 'assets/flags/Argentina.svg', name: this.translate.instant('ARGENTINA'), value: 'Argentina', code: 'ar' });
    _flags.push({ image: 'assets/flags/Bolivia.svg', name: this.translate.instant('BOLIVIA'), value: 'Bolivia', code: 'bo' });
    _flags.push({ image: 'assets/flags/Brasil.svg', name: this.translate.instant('BRASIL'), value: 'Brasil', code: 'br' });
    _flags.push({ image: 'assets/flags/Chile.svg', name: this.translate.instant('CHILE'), value: 'Chile', code: 'cl' });
    _flags.push({ image: 'assets/flags/Colombia.svg', name: this.translate.instant('COLOMBIA'), value: 'Colombia', code: 'co' });
    _flags.push({ image: 'assets/flags/Costa_Rica.svg', name: this.translate.instant('COSTA_RICA'), value: 'Costa Rica', code: 'cr' });
    _flags.push({ image: 'assets/flags/Cuba.svg', name: this.translate.instant('CUBA'), value: 'Cuba', code: 'cu' });
    _flags.push({ image: 'assets/flags/Ecuador.svg', name: this.translate.instant('ECUADOR'), value: 'Ecuador', code: 'ec' });
    _flags.push({ image: 'assets/flags/El_Salvador.svg', name: this.translate.instant('EL_SALVADOR'), value: 'El Salvador', code: 'sv' });
    _flags.push({ image: 'assets/flags/Guatemala.svg', name: this.translate.instant('GUATEMALA'), value: 'Guatemala', code: 'gt' });
    _flags.push({ image: 'assets/flags/Honduras.svg', name: this.translate.instant('HONDURAS'), value: 'Honduras', code: 'hn' });
    _flags.push({ image: 'assets/flags/México.svg', name: this.translate.instant('MEXICO'), value: 'México', code: 'mx' });
    _flags.push({ image: 'assets/flags/Nicaragua.svg', name: this.translate.instant('NICARAGUA'), value: 'Nicaragua', code: 'ni' });
    _flags.push({ image: 'assets/flags/Panamá.svg', name: this.translate.instant('PANAMA'), value: 'Panamá', code: 'pa' });
    _flags.push({ image: 'assets/flags/Paraguay.svg', name: this.translate.instant('PARAGUAY'), value: 'Paraguay', code: 'py' });
    _flags.push({ image: 'assets/flags/Perú.svg', name: this.translate.instant('PERU'), value: 'Perú', code: 'pe' });
    _flags.push({ image: 'assets/flags/República_Dominicana.svg', name: this.translate.instant('REP_DOM'), value: 'República Dominicana', code: 'do' });
    _flags.push({ image: 'assets/flags/Uruguay.svg', name: this.translate.instant('URUGUAY'), value: 'Uruguay', code: 'uy' });
    _flags.push({ image: 'assets/flags/Venezuela.svg', name: this.translate.instant('VENEZUELA'), value: 'Venezuela', code: 've' });
    _flags.push({ image: 'assets/flags/Sudafrica.svg', name: this.translate.instant('SUDAFRICA'), value: 'Sudafrica', code: "za" });

    for (let i = 0; i < _flags.length; i++) {
      this.countryCodes.push(_flags[i].code);
    }

    return _flags;
  }

  loadAges(): any {
    const _ages = [];
    _ages.push({ name: '15-17', value: '15-17' });
    _ages.push({ name: '18-20', value: '18-20' });
    _ages.push({ name: '21-25', value: '21-25' });
    _ages.push({ name: '26-30', value: '26-30' });
    _ages.push({ name: '31-35', value: '31-35' });
    _ages.push({ name: '36-40', value: '36-40' });
    _ages.push({ name: '41-45', value: '41-45' });
    _ages.push({ name: '46-50', value: '46-50' });
    _ages.push({ name: '51-55', value: '51-55' });
    _ages.push({ name: '>55', value: '>55' });

    return _ages;
  }

  /**
   * Carga un arreglo de avatares predefinidos.
   * @returns Arreglo de avatares.
   * @JuanUicab-Lumston
   */
  loadAvatars(): iAvatar[] {
    let _avatars: iAvatar[] = [];

    _avatars.push({ image: 'assets/avatars/fem1.png', name: 'Avatar1', value: 'fem1' });
    _avatars.push({ image: 'assets/avatars/fem2.png', name: 'Avatar2', value: 'fem2' });
    _avatars.push({ image: 'assets/avatars/fem3.png', name: 'Avatar3', value: 'fem3' });
    _avatars.push({ image: 'assets/avatars/fem4.png', name: 'Avatar4', value: 'fem4' });
    _avatars.push({ image: 'assets/avatars/fem5.png', name: 'Avatar5', value: 'fem5' });
    _avatars.push({ image: 'assets/avatars/fem6.png', name: 'Avatar6', value: 'fem6' });
    _avatars.push({ image: 'assets/avatars/fem7.png', name: 'Avatar7', value: 'fem7' });
    _avatars.push({ image: 'assets/avatars/fem8.png', name: 'Avatar8', value: 'fem8' });
    _avatars.push({ image: 'assets/avatars/mas1.png', name: 'Avatar9', value: 'mas1' });
    _avatars.push({ image: 'assets/avatars/fem9.png', name: 'Avatar10', value: 'fem9' });
    _avatars.push({ image: 'assets/avatars/fem10.png', name: 'Avatar11', value: 'fem10' });
    _avatars.push({ image: 'assets/avatars/mas2.png', name: 'Avatar12', value: 'mas2' });
    _avatars.push({ image: 'assets/avatars/fem11.png', name: 'Avatar13', value: 'fem11' });
    _avatars.push({ image: 'assets/avatars/fem12.png', name: 'Avatar14', value: 'fem12' });
    _avatars.push({ image: 'assets/avatars/mas3.png', name: 'Avatar15', value: 'mas3' });
    _avatars.push({ image: 'assets/avatars/fem13.png', name: 'Avatar16', value: 'fem13' });
    _avatars.push({ image: 'assets/avatars/fem14.png', name: 'Avatar17', value: 'fem14' });

    return _avatars;
  }

  /**
   * Agrega el avatar seleccionado al formulario.
   * @param Avatar Avatar seleccionado por el usuario.
   * @JuanUicab-Lumston
   */
  selectAvatar(Avatar: iAvatar): void {
    this.FORM.patchValue({
      avatar: Avatar.value,
    });
  }

  /**
   * Muestra el mensaje de bienvenida en la primera carga si no se ha abierto la ventana de chat.
   * @JuanUicab-Lumston
   */
  showWelcomeMessage(): void {
    if (this.FIRST_CHARGE) {
      this.SHOW_MESSAGE = !this.SHOW_MESSAGE;
      // this.showChat();
      //this.ALL_COUNTRIES = this.loadCountries();
      this.ALL_AVATARS = this.loadAvatars();
      this.ALL_AGES = this.loadAges();
      this.createInitialForm();
    }
  }

  /**
   * Crea el formulario inicial para el usuario.
   * @JuanUicab-Lumston
   */
  createInitialForm(): void {
    this.ELIMINAR_SALUDO = 3;
    this.ELIMINAR_DATOS_INICIALES = 3;
    this.FORM = this.formBuilder.group({
      country: [null, [Validators.required]],
      avatar: [null, [Validators.required]],
      age: [null, [Validators.required]],
    });
  }

  /**
   * Oculta automáticamente el mensaje de bienvenida después de 5 segundos.
   * @JuanUicab-Lumston
   */
  autoHiddeMessaje(): void {
    setTimeout(() => {
      this.SHOW_MESSAGE = false;
    }, 5000);
  }

  async ngOnInit(): Promise<void> {
    this.LOGO_BOT = 'assets/avatars/new-logo-bot.png';
    this.LOGO_HEADER = 'assets/logo/logo-header-es.png';

    this.getPrivacity();

    setTimeout(() => {
      this.showWelcomeMessage();
      this.autoHiddeMessaje();
    }, 2000);

    //await this.initChatbot();
  }

  /**
   * Verifica la existencia del token o UUID de la sesión.
   * @returns Token o UUID válido para la sesión.
   * @JuanUicab-Lumston
   */
  verifyUUID(): string {
    const _token = localStorage.getItem('Token');

    if (_token === null) {
      let _token: string = uuidv4();
      let _cleanToken = _token.replace('-', '').replace('-', '').replace('-', '').replace('-', '');
      localStorage.setItem('Token', _cleanToken);
      return _cleanToken;
    } else {
      return _token;
    }
  }

  async initChatbot() {
    localStorage.clear();
    localStorage.setItem('lang', this.userLang);
    const _token = this.verifyUUID();

    this.SETTINGS_CHATBOT = {
      text: 'hola',
      sessionId: _token,
      session_state: {
        dialogAction: {
          type: 'ElicitSlot',
          slotToElicit: 'PaisOrigen',
        },
        intent: {
          name: 'BienvenidoIntent',
          state: 'InProgress',
          confirmationState: 'None',
        },
        originatingRequestId: 'a389533b-4b10-4ea5-85ca-51e94b55527c',
      },
    };

    const _result = (await this.awsLexService.sendLex2(this.SETTINGS_CHATBOT)) as any;

    //const jsonBody = JSON.parse(_result.body);
    //this.LAST_BODY = jsonBody;

    this.LAST_BODY = _result.body;

    this.SETTINGS_CHATBOT.session_state = this.LAST_BODY.sessionState;
  }

  deleteForm() {
    for (let i = 0; i < this.ELIMINAR_SALUDO; i++) {
      const placeholder1 = this.MESSAGES.nativeElement.querySelector('.header');
      if (placeholder1 !== null) {
        this.renderer.removeChild(this.MESSAGES.nativeElement, placeholder1);
      }
    }

    for (let i = 0; i < this.ELIMINAR_DATOS_INICIALES; i++) {
      const placeholder1 = this.MESSAGES.nativeElement.querySelector('.initForm');
      if (placeholder1 !== null) {
        this.renderer.removeChild(this.MESSAGES.nativeElement, placeholder1);
      }
    }
  }

  reassignBodySessionState(NewBody: any) {
    //const jsonNewBody = JSON.parse(NewBody)

    this.LAST_BODY = NewBody;
    this.SETTINGS_CHATBOT.session_state = this.LAST_BODY.sessionState;
  }

  async sendName() {
    //set name
    this.SETTINGS_CHATBOT.text = this.FORM.controls['name'].value;
    const _resultName = (await this.awsLexService.sendLex2(this.SETTINGS_CHATBOT)) as any;
    this.reassignBodySessionState(_resultName.body);
  }

  async sendAge() {
    //set age
    this.SETTINGS_CHATBOT.text = this.FORM.controls['age'].value;
    const _resultName = (await this.awsLexService.sendLex2(this.SETTINGS_CHATBOT)) as any;
    this.reassignBodySessionState(_resultName.body);
  }

  async sendCountry() {
    //set country
    this.SETTINGS_CHATBOT.text = this.FORM.controls['country'].value.value;
    const _resultCountry = (await this.awsLexService.sendLex2(this.SETTINGS_CHATBOT)) as any;
    this.reassignBodySessionState(_resultCountry.body);
  }

  async sendAvatar() {
    //set Avatar
    this.SETTINGS_CHATBOT.text = this.FORM.controls['avatar'].value;
    const _resultAvatar = (await this.awsLexService.sendLex2(this.SETTINGS_CHATBOT)) as any;
    this.reassignBodySessionState(_resultAvatar.body);
  }

  async getBasicData(): Promise<void> {
    this.deleteForm();
    this.SHOW_BUTTON = !this.SHOW_BUTTON;
    this.INCHAT_CONTENT = true;
    this.createMessageGif(); //ok

    // await this.sendName();
    await this.sendAge();
    await this.sendCountry();
    await this.sendAvatar();

    this.createMessage();

    this.removePlaceholderDotsElements();

    this.AVATAR_IMG = this.FORM.controls['avatar'].value;
  }

  // /**
  //  * Agrega un enter a un elemento.
  //  * @param Element Elemento al cuál se le quiere agregar el enter.
  //  * @JuanUicab-Lumston
  //  */
  // addEnter(Element: any): void {
  //     this.renderer.appendChild(Element, this.renderer.createElement('br'));
  // }

  // /**
  //  * Agrega un elemento dentro del contenedor principal.
  //  * @param Element Elemento al cuál se le quiere agregar otro.
  //  * @JuanUicab-Lumston
  //  */
  // addToMainElement(Element: any): void {
  //     this.renderer.appendChild(this.MESSAGES.nativeElement, Element);
  // }

  // /**
  //  * Muestra u oculta el char al usuario.
  //  * @JuanUicab-Lumston
  //  */
  // showChat(): void {
  //     if (this.FIRST_CHARGE) {
  //         const _history: iHistory[] = JSON.parse(localStorage.getItem('History') as string);
  //         if (_history !== null && _history.length > 0) {
  //             this.cargaHistorial();
  //         } else {
  //             this.startChat();
  //         }
  //     }
  //     this.FIRST_CHARGE = false;
  //     this.SHOW_CHAT = true; // !this.SHOW_CHAT;
  //     this.SHOW_MESSAGE = false;
  // }

  /**
   * Limpia el historial almacenado en el localstorage.
   * @JuanUicab-Lumston
   */
  async clearHistory() {
    if (!this.BORRA_HISTORIAL) {
      // this.deleteAllCookies();
      this.BORRA_HISTORIAL = true;

      localStorage.removeItem('SessionAttributes');
      localStorage.removeItem('History');
      localStorage.removeItem('Token');
      this.HISTORIAL_BOT = [];

      const childElements: any[] = this.MESSAGES.nativeElement.children;
      for (let i: number = childElements.length - 1; i >= 0; i--) {
        this.renderer.removeChild(this.MESSAGES.nativeElement, childElements[i]);
      }

      this.PLACEHOLDER = 'Seleccione una opción...';
      this.DISABLED_INPUT = true;
      this.HORA = '';
      //this.createMessageGif();  //no
      // localStorage.setItem('Token', uuidv4());
      let _token: string = uuidv4();
      let _cleanToken = _token.replace('-', '').replace('-', '').replace('-', '').replace('-', '');
      localStorage.setItem('Token', _cleanToken);
      const _response = await this.sendUserResponse('init');
      this.removePlaceholderDotsElements();
      // this.createMessage(_response);
      this.BORRA_HISTORIAL = false;
      this.FINISH_ACTIONS = false;
    }
  }

  // /**
  //  * Inicia el char con el bot.
  //  * @JuanUicab-Lumston
  //  */
  // async startChat(): Promise<void> {
  //     this.createMessageGif();
  //     // Obtiene el historial almacenado.
  //     const _history: iHistory[] = JSON.parse(localStorage.getItem('History') as string);
  //     const _answerUser: iHistory[] = _history !== null ? _history.filter((x) => x.type === eTypeMessage.AnswerUser) : [];
  //     let response = _answerUser.length > 0 ? await this.sendUserResponse(_answerUser[_answerUser.length - 1].Content.message) : await this.sendUserResponse('init');
  //     this.removePlaceholderDotsElements();
  //     // this.createMessage(response);
  //     this.POR_ELIMINAR = response.responseCard ? response.responseCard.genericAttachments.length : 0;
  //     this.scrollDown();
  // }

  /**
   * Envía la respuesta seleccionada del usuario a Lex.
   * @param Response Respuesta seleccionada por el usuario.
   * @returns Respuesta del envío del mensaje de usuario.
   * @JuanUicab-Lumston
   */
  async sendUserResponse(Response: string): Promise<any> {
    let _result;
    if (Response === 'init' && localStorage.getItem('SessionAttributes') === null) {
      // /**Se generan los session attributes iniciales. */
      // const _localAttributes: any = {
      //     site_origin: document.referrer,
      //     domain: document.referrer !== '' ? new URL(document.referrer).host : this.window.location.host,
      // };
      // /**Se obtienen los session attributes existentes en el local storage. */
      // const _getSessionAttributes: any = localStorage.getItem('SessionAttributes');
      // const _createSessionAttributes = _getSessionAttributes !== null ? JSON.parse(localStorage.getItem('SessionAttributes') as string) : _localAttributes;
      // let stuff: { [key: string]: string } = {};
      // Object.keys(_createSessionAttributes).forEach((key) => {
      //     stuff[`${key}`] = _createSessionAttributes[key];
      // });
      /**Se genera el objeto con los parámetros necesarios. */
      // this.LEX_MESSAGE = {
      //     bot_alias: environment.BOT_ALIAS,
      //     bot_name: environment.BOT_NAME,
      //     inputText: Response,
      //     user_id: localStorage.getItem('Token') as string,
      //     sessionAttributes: stuff,
      //     requestAttributes: {},
      // };
      this.SETTINGS_CHATBOT.text = Response;

      // const _result = (await this.awsLexService.sendLexData(this.LEX_MESSAGE)) as any;
      _result = (await this.awsLexService.sendLex2(this.SETTINGS_CHATBOT)) as any;
      this.LAST_BODY = _result.body;
      this.SETTINGS_CHATBOT.session_state = this.LAST_BODY.sessionState;
      // _resp = JSON.parse(_result.body);

      // /**Se obtienen los session attributes de respuesta. */
      // const _sessionAttributes: any = _resp.sessionAttributes;
      // /**Se convierten a string los session attributes actuales. */
      // const _init: boolean = Response === 'init';
      // const _newSessionAttributes = _init ? _sessionAttributes : JSON.parse(_sessionAttributes);
      // /**Se almacenan los session attributes en el local storage. */
      // localStorage.setItem('SessionAttributes', JSON.stringify(_newSessionAttributes, null, 2));
    } else {
      // const _localAttributes: any = {
      //     site_origin: document.referrer,
      //     domain: document.referrer !== '' ? new URL(document.referrer).host : this.window.location.host,
      //     // site_origin: this.window.location.href,
      //     // domain: this.window.location.host,
      //     // domain: 'www.google.com',
      // };
      // const _getSessionAttributes: any = localStorage.getItem('SessionAttributes');
      // const _createSessionAttributes = _getSessionAttributes !== null ? JSON.parse(localStorage.getItem('SessionAttributes') as string) : _localAttributes;
      // let stuff: { [key: string]: string } = {};
      // Object.keys(_createSessionAttributes).forEach((key) => {
      //     stuff[`${key}`] = _createSessionAttributes[key];
      // });
      // this.LEX_MESSAGE = {
      //     bot_alias: environment.BOT_ALIAS,
      //     bot_name: environment.BOT_NAME,
      //     inputText: Response,
      //     user_id: localStorage.getItem('Token') as string,
      //     sessionAttributes: stuff,
      //     requestAttributes: {},
      // };
      // const _result = (await this.awsLexService.sendLexData(this.LEX_MESSAGE)) as any;
      // _resp = JSON.parse(_result.body);
      // _resp.sessionAttributes.key_validation_show_models = _resp.sessionAttributes.key_validation_show_models ?? 'SHOW_MODELS';
      // localStorage.setItem('SessionAttributes', JSON.stringify(_resp.sessionAttributes, null, 2));

      this.SETTINGS_CHATBOT.text = Response;

      // const _result = (await this.awsLexService.sendLexData(this.LEX_MESSAGE)) as any;
      _result = (await this.awsLexService.sendLex2(this.SETTINGS_CHATBOT)) as any;
      this.LAST_BODY = _result.body;
      this.SETTINGS_CHATBOT.session_state = this.LAST_BODY.sessionState;
    }

    return _result;
  }

  createMessage(): void {
    // this.PLACEHOLDER = 'Seleccione una opción...';
    this.createHora();
    let _div = this.renderer.createElement('div');
    // if (Response.responseCard !== undefined && Response.responseCard.genericAttachments[0].imageUrl !== undefined) {
    //     this.showImages(_div, Response.responseCard.genericAttachments);
    // }

    // const _interaccionBot: iHistory = {
    //     type: Response.responseCard && Response.responseCard.genericAttachments[0].imageUrl ? eTypeMessage.Galery : eTypeMessage.Server,
    //     Content: {
    //         message: Response.message,
    //         galery: Response.responseCard ? Response.responseCard.genericAttachments : undefined,
    //     },
    // };

    // this.guardarEnHistorial(_interaccionBot);
    const _contentBody = this.LAST_BODY.messages as iMessageLex[];
    const _messages = _contentBody.filter((x) => x.contentType === eContentType.PlainText);

    const _buttons = _contentBody.filter((x) => x.contentType === eContentType.ImageResponseCard);

    if (_messages.length > 1) {
      for (let i: number = 0; i < _messages.length; i++) {
        setTimeout(
          () => {
            this.removePlaceholderDotsElements();
            let _div = this.renderer.createElement('div');
            this.separateTextModelDescription(_div, _messages[i].content);
            this.renderer.addClass(_div, 'question');
            let _uniqueImg = this.renderer.createElement('img');
            this.renderer.setProperty(_uniqueImg, 'src', this.LOGO_BOT);
            this.renderer.addClass(_uniqueImg, 'botMessage');
            this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
            this.renderer.appendChild(_div, _uniqueImg);
            this.renderer.appendChild(this.MESSAGES.nativeElement, _div);

            this.createMessageGif(); //aqui es el mero

            if (_messages.length - 1 === i && _buttons.length > 0) {
              this.removePlaceholderDotsElementsById();
              this.backMenuFlag = 1;

              if (_buttons.length == 1) {
                //_buttons[0].imageResponseCard?.buttons.push({text:'Regresar',value:'regreso'}); //Back button to methods in conversation
                for (const _button of _buttons) {
                  this.POR_ELIMINAR += _button.imageResponseCard !== undefined ? _button.imageResponseCard?.buttons.length : 0;
                  _button.imageResponseCard !== undefined ? this.createButtons(_button.imageResponseCard?.buttons) : [];
                }
              } else {
                const auxButtonsArray: any = _buttons;
                const multipleArraybuttons: any = [];
                const finalButtonsArray: any = [];
                const auxImageArray: any = [];
                for (let i = 0; i < auxButtonsArray.length; i++) {
                  multipleArraybuttons.push(auxButtonsArray[i].imageResponseCard.buttons);
                }
                for (let i = 0; i < multipleArraybuttons.length; i++) {
                  if (typeof multipleArraybuttons[i] != 'undefined') {
                    for (let j = 0; j < multipleArraybuttons[i].length; j++) {
                      finalButtonsArray.push(multipleArraybuttons[i][j]);
                    }
                  } else {
                    //auxImageArray.push(auxButtonsArray[1].imageResponseCard)
                    //this.showImages(_div, auxImageArray);

                    if (auxButtonsArray[1].imageResponseCard.imageUrl && this.LAST_BODY.sessionState.sessionAttributes.urlPresigned) {
                      window.open(this.LAST_BODY.sessionState.sessionAttributes.urlPresigned, '_blank');
                    }
                  }
                }
                this.POR_ELIMINAR += finalButtonsArray.length;

                this.createButtons(finalButtonsArray);
              }

              //this.removePlaceholderDotsElements();
            }

            //this.removePlaceholderDotsElements();
          },
          i > 0 ? this.TIME * i : 0
        );
      }
    } else {
      this.removePlaceholderDotsElementsById();

      this.separateTextModelDescription(_div, _messages[0].content);
      this.renderer.addClass(_div, 'question');
      let _uniqueImg = this.renderer.createElement('img');
      this.renderer.setProperty(_uniqueImg, 'src', this.LOGO_BOT);
      this.renderer.addClass(_uniqueImg, 'botMessage');
      this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
      this.renderer.appendChild(_div, _uniqueImg);
      this.renderer.appendChild(this.MESSAGES.nativeElement, _div);

      if (_buttons.length > 0) {
        if (_buttons.length == 1) {
          //_buttons[0].imageResponseCard?.buttons.push({text:'Regresar',value:'regreso'}); //Back button to methods in conversation
          for (const _button of _buttons) {
            this.POR_ELIMINAR += _button.imageResponseCard !== undefined ? _button.imageResponseCard?.buttons.length : 0;
            _button.imageResponseCard !== undefined ? this.createButtons(_button.imageResponseCard?.buttons) : [];
          }
        } else {
          const auxButtonsArray: any = _buttons;
          const multipleArraybuttons: any = [];
          const finalButtonsArray: any = [];
          const auxImageArray: any = [];
          for (let i = 0; i < auxButtonsArray.length; i++) {
            multipleArraybuttons.push(auxButtonsArray[i].imageResponseCard.buttons);
          }
          for (let i = 0; i < multipleArraybuttons.length; i++) {
            if (typeof multipleArraybuttons[i] != 'undefined') {
              for (let j = 0; j < multipleArraybuttons[i].length; j++) {
                finalButtonsArray.push(multipleArraybuttons[i][j]);
              }
            } else {
              //auxImageArray.push(auxButtonsArray[1].imageResponseCard)
              //this.showImages(_div, auxImageArray);

              if (auxButtonsArray[1].imageResponseCard.imageUrl && this.LAST_BODY.sessionState.sessionAttributes.urlPresigned) {
                window.open(this.LAST_BODY.sessionState.sessionAttributes.urlPresigned, '_blank');
              }
            }
          }
          this.POR_ELIMINAR += finalButtonsArray.length;

          this.createButtons(finalButtonsArray);
        }
      }
    }

    // // this.separateTextModelDescription(_div, this.LAST_BODY.messages[0].content);
    // this.renderer.addClass(_div, 'question');
    // let _uniqueImg = this.renderer.createElement('img');
    // this.renderer.setProperty(_uniqueImg, 'src', this.LOGO_BOT);
    // this.renderer.addClass(_uniqueImg, 'botMessage');
    // this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
    // this.renderer.appendChild(_div, _uniqueImg);
    // this.renderer.appendChild(this.MESSAGES.nativeElement, _div);
    // // this.POR_ELIMINAR = this.LAST_BODY.messages[1].imageResponseCard.buttons.length;
    // // this.createButtons(this.LAST_BODY.messages[1].imageResponseCard.buttons);

    // if (_buttons.length > 0) {
    //     for (const _button of _buttons) {
    //         this.POR_ELIMINAR += _button.imageResponseCard !== undefined ? _button.imageResponseCard?.buttons.length : 0;
    //         const _arrayButtons = _button.imageResponseCard !== undefined ? this.createButtons(_button.imageResponseCard?.buttons) : [];
    //     }
    // }

    // if (Response.dialogState !== 'Fulfilled') {
    //     if (Response.responseCard !== undefined) {
    //         this.SEND_MESSAGE = false;
    //         this.POR_ELIMINAR = Response.responseCard.genericAttachments.length;
    //         if (Response.responseCard.genericAttachments[0].imageUrl && Response.responseCard.genericAttachments[0].buttons !== undefined) {
    //             this.createButtons(Response.responseCard.genericAttachments[0].buttons);
    //         } else {
    //             const _buttons: any[] = [];
    //             Response.responseCard.genericAttachments.forEach((_btn: any) => {
    //                 _buttons.push(..._btn.buttons);
    //             });
    //             this.createButtons(_buttons);
    //         }
    //     } else {
    //         this.SEND_MESSAGE = true;
    //     }
    // } else {
    //     this.FINISH_ACTIONS = true;
    //     this.SEND_MESSAGE = false;
    //     this.scrollDown();
    //     return;
    // }

    // this.PLACEHOLDER = this.SEND_MESSAGE ? 'Escriba aquí...' : 'Seleccione una opción...';
    // this.DISABLED_INPUT = !this.SEND_MESSAGE;
    this.scrollDown();
    //this.enableHeaderMenuButtons() //NOT HERE
  }

  /**
   * Crea div con la hora en que fue creado el mensaje.
   * @JuanUicab-Lumston
   */
  createHora(): void {
    let _div = this.renderer.createElement('div');
    this.renderer.addClass(_div, 'hora');
    const _dateNow = new Date(Date.now());
    const _horaActual = `${_dateNow.getHours().toString().padStart(2, '0')}:${_dateNow.getMinutes().toString().padStart(2, '0')}`;
    const _hora = this.renderer.createText(_horaActual);
    if (this.HORA === '' || this.HORA !== _hora.data) {
      this.renderer.appendChild(_div, _hora);
      this.renderer.appendChild(this.MESSAGES.nativeElement, _div);
      this.HORA = _hora.data;
    }
  }

  /**
   * Crea respuesta del gif de ejemplo para navegación.
   * @JuanUicab-Lumston
   */
  createMessageGif(): void {
    this.disableHeaderMenuButtons();

    this.POR_ELIMINAR_DOTS = 1;

    this.DOTS_GIF = 'assets/icons/dots.gif';
    let _div = this.renderer.createElement('div');

    this.renderer.addClass(_div, 'question');
    this.renderer.setAttribute(_div, 'id', 'gifId-' + this.GIF_CONTROL_ID);

    let _dots = this.renderer.createElement('img');
    this.renderer.setProperty(_dots, 'src', this.DOTS_GIF);
    this.renderer.addClass(_dots, 'dotsGif');
    this.renderer.setProperty(_dots, 'loading', 'lazy');
    this.renderer.appendChild(_div, _dots);

    this.renderer.addClass(_div, 'gif');
    let _uniqueImg = this.renderer.createElement('img');
    this.renderer.setProperty(_uniqueImg, 'src', this.LOGO_BOT);
    this.renderer.addClass(_uniqueImg, 'botMessage');
    this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
    this.renderer.appendChild(_div, _uniqueImg);
    this.renderer.appendChild(this.MESSAGES.nativeElement, _div);

    this.scrollDown();
    this.GIF_CONTROL_ID += 1;
  }

  /**
   * Separa de manera coherente el texto de la respuesta de Lex.
   * @param Element Elemento al que se le agregará el texto a tratar.
   * @param Text Texto raw según respuesta del servidor.
   * @JuanUicab-Lumston
   */
  separateTextModelDescription(Element: any, Text: any): void {
    let _separated = Text.split('\n');
    for (let i = 0; i < _separated.length; i++) {
      let _text = this.renderer.createText(_separated[i]);
      this.renderer.appendChild(Element, _text);
      const _subSplit = _separated.length > i + 1 ? _separated[i + 1].split('') : [];
      if (_subSplit.length > 0 && _subSplit[0] !== ' ') {
        let _p = this.renderer.createElement('br');
        this.renderer.appendChild(Element, _p);
      }
    }
  }

  /**
   * Elimina el contenido de botones a selección del usuario y crea un botón sin acción.
   * @param Text Texto que contiene el botón seleccionado.
   * @JuanUicab-Lumston
   */
  removePlaceholderElements(): void {
    for (let i = 0; i < this.POR_ELIMINAR; i++) {
      const placeholder1 = this.MESSAGES.nativeElement.querySelector('.text-right');
      if (placeholder1 !== null) {
        this.renderer.removeChild(this.MESSAGES.nativeElement, placeholder1);
      }
    }
  }

  /**
   * Elimina el contenido de botones a selección del usuario y crea un botón sin acción.
   * @param Text Texto que contiene el botón seleccionado.
   * @JuanUicab-Lumston
   */
  removePlaceholderDotsElements(): void {
    for (let i = 0; i < this.POR_ELIMINAR_DOTS; i++) {
      const placeholder1 = this.MESSAGES.nativeElement.querySelector('.gif');
      if (placeholder1 !== null) {
        this.renderer.removeChild(this.MESSAGES.nativeElement, placeholder1);
      }
    }
  }

  removePlaceholderDotsElementsById() {
    const idToRemove = this.GIF_CONTROL_ID - 1;

    this.renderer.setStyle(this.MESSAGES.nativeElement.querySelector('#gifId-' + idToRemove), 'display', 'none');
    //this.renderer.setStyle(this.MESSAGES.nativeElement.querySelector('#gifId-'+this.GIF_CONTROL_ID),'display','none');
  }

  /**
   * Crea los botones de elección para los usuarios.
   * @param Text Mensaje de interacción del usuario.
   * @JuanUicab-Lumston
   */
  creaRespuestaUsuario(Text: string): void {
    let _div = this.renderer.createElement('div');
    this.renderer.addClass(_div, 'botonesRespuesta');
    let _uniqueImg = this.renderer.createElement('img');
    this.renderer.setProperty(_uniqueImg, 'src', 'assets/avatars/' + this.AVATAR_IMG + '.png');
    this.renderer.addClass(_uniqueImg, 'avatarImg');
    const _button = this.renderer.createElement('button');
    this.renderer.addClass(_button, 'respuesta');
    const _buttonText = this.renderer.createText(Text);

    this.renderer.appendChild(_div, _uniqueImg);
    this.renderer.appendChild(_button, _buttonText);
    this.renderer.appendChild(_div, _button);
    this.renderer.appendChild(this.MESSAGES.nativeElement, _div);

    const _interaccionBot: iHistory = {
      type: eTypeMessage.User,
      Content: {
        message: Text,
      },
    };
    //this.guardarEnHistorial(_interaccionBot);
  }

  /**
   * Crea los componentes y apartados necesarios para la interacción del usuario con el bot.
   * @param Answer Respuesta seleccionada por el us uario.
   * @JuanUicab-Lumston
   */
  async createComponentsAnswerUser(Text: string, Answer: string): Promise<void> {
    // if (this.FINISH_ACTIONS) {
    //     alert('Debe reiniciar la búsqueda para interactuar nuevamente.');
    //     return;
    // }

    this.removePlaceholderElements();
    this.creaRespuestaUsuario(Text);
    this.createMessageGif();
    const _response = await this.sendUserResponse(Answer);
    this.reassignBodySessionState(_response.body);
    this.removePlaceholderDotsElements();
    this.createMessage();
  }
  async createComponentsAnswerUserWhithoutElement(Answer: string): Promise<void> {
    // if (this.FINISH_ACTIONS) {
    //     alert('Debe reiniciar la búsqueda para interactuar nuevamente.');
    //     return;
    // }

    this.removePlaceholderElements();
    this.createMessageGif();
    const _response = await this.sendUserResponse(Answer);
    this.reassignBodySessionState(_response.body);
    this.removePlaceholderDotsElements();
    this.removePlaceholderDotsElementsById();
    this.createMessage();
  }

  /**
   * Crea un slide o muestra una imagen única.
   * @param Padre Apartado donde va a agregarse el slider.
   * @param Images Arreglo de imágenes para el slider.
   * @JuanUicab-Lumston
   */
  showImages(Padre: any, Images: any[]): void {
    let _imagesList: string[] = [];

    Images.forEach((img) => {
      _imagesList.push(img.imageUrl);
    });

    //Si la imagen es única.
    if (_imagesList.length === 1) {
      let _uniqueImg = this.renderer.createElement('img');
      this.renderer.setProperty(_uniqueImg, 'src', _imagesList[0]);
      this.renderer.addClass(_uniqueImg, 'uniqueImage');
      this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
      this.renderer.appendChild(Padre, _uniqueImg);
      let _hr = this.renderer.createElement('hr');
    } else {
      let _divSlides = this.renderer.createElement('div');
      this.renderer.addClass(_divSlides, 'w3-content');
      this.renderer.addClass(_divSlides, 'w3-display-container');
      _imagesList.forEach((_imgUrl) => {
        let _img = this.renderer.createElement('img');
        this.renderer.setProperty(_img, 'src', _imgUrl);
        this.renderer.addClass(_img, `mySlides`);
        this.renderer.addClass(_img, `mySlides${this.NUMBER_SLIDER}`);
        this.renderer.addClass(_img, 'fade');
        this.renderer.setProperty(_img, 'loading', 'lazy');
        this.renderer.appendChild(_divSlides, _img);
      });

      const _numberSlide: string = `mySlides${this.NUMBER_SLIDER}`;

      let _btnLeft = this.renderer.createElement('button');
      this.renderer.addClass(_btnLeft, 'w3-button');
      this.renderer.addClass(_btnLeft, 'w3-white');
      this.renderer.addClass(_btnLeft, 'w3-display-left');
      let _btnLeftImg = this.renderer.createElement('img');
      this.renderer.setProperty(_btnLeftImg, 'src', 'assets/icons/izquierda.svg');
      this.renderer.appendChild(_btnLeft, _btnLeftImg);
      this.renderer.listen(_btnLeft, 'click', () => {
        this.plusDivs(-1, _numberSlide);
      });

      let _btnRight = this.renderer.createElement('button');
      this.renderer.addClass(_btnRight, 'w3-button');
      this.renderer.addClass(_btnRight, 'w3-white');
      this.renderer.addClass(_btnRight, 'w3-display-right');
      let _btnRightImg = this.renderer.createElement('img');
      this.renderer.setProperty(_btnRightImg, 'src', 'assets/icons/derecha.svg');
      this.renderer.appendChild(_btnRight, _btnRightImg);
      this.renderer.listen(_btnRight, 'click', () => {
        this.plusDivs(1, _numberSlide);
      });

      this.renderer.appendChild(_divSlides, _btnLeft);
      this.renderer.appendChild(_divSlides, _btnRight);
      this.renderer.appendChild(Padre, _divSlides);
    }

    let _hr = this.renderer.createElement('hr');
    this.renderer.appendChild(Padre, _hr);
  }

  /**
   * Navega entre las imágenes del slider.
   * @param n Muestra la imagen siguiente (1) o anterior (-1);
   * @param Slider Slider sobre el que se realizará la acción.
   * @JuanUicab-Lumston
   */
  plusDivs(n: number, Slider: string): void {
    this.showDivs((this.SLIDE_INDEX += n), Slider);
  }

  /**
   * Activará el slider creado.
   * @param n Número de imagen que se mostrará.
   * @param Slider Slider en actual a trabajar.
   * @JuanUicab-Lumston
   */
  showDivs(n: number, Slider: string): void {
    let x = document.getElementsByClassName(Slider);
    if (x.length > 0) {
      if (n > x.length) {
        this.SLIDE_INDEX = 1;
      }
      if (n < 1) {
        this.SLIDE_INDEX = x.length;
      }
      for (let i: number = 0; i < x.length; i++) {
        x[i].setAttribute('style', 'display: none');
      }
      x[this.SLIDE_INDEX - 1].setAttribute('style', 'display: block');
    }
  }

  /**
   * Regresa el scroll hasta abajo de manera automática.
   * @JuanUicab-Lumston
   */
  scrollDown(): void {
    this.showDivs(1, 'mySlides' + this.NUMBER_SLIDER);
    this.NUMBER_SLIDER++;
    setTimeout(() => {
      let objDiv = document.getElementById('botOrganon') as HTMLElement;
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 500);
  }

  /**
   * Agrega botones a la página.
   * @param Buttons Arreglo de botones para agregar a la página.
   * @JuanUicab-Lumston
   */
  createButtons(Buttons: any[]): void {
    // const _interaccionBot: iHistory = {
    //     type: eTypeMessage.AnswerUser,
    //     Content: {
    //         message: Buttons,
    //     },
    // };
    // this.guardarEnHistorial(_interaccionBot);

    let _div = this.renderer.createElement('div');
    this.renderer.addClass(_div, 'text-right');
    Buttons.forEach((btn) => {
      const _button = this.renderer.createElement('button');
      this.renderer.addClass(_button, 'eliminar');
      const _buttonText = this.renderer.createText(btn.text);
      this.renderer.appendChild(_button, _buttonText);
      this.renderer.appendChild(_div, _button);
      this.renderer.listen(_button, 'click', () => {
        this.createComponentsAnswerUser(`${btn.text}`, `${btn.value}`);
      });
    });
    this.renderer.appendChild(this.MESSAGES.nativeElement, _div);
    this.enableHeaderMenuButtons();
  }

  // /**
  //  * Envía los datos del formulario actual.
  //  * @param f Formulario actual.
  //  * @JuanUicab-Lumston
  //  */
  // async onSubmit(f: NgForm): Promise<void> {
  //     if (this.SEND_MESSAGE && f.value.message !== null) {
  //         this.DISABLED_INPUT = true;
  //         const _historyUser: iHistory = {
  //             type: eTypeMessage.User,
  //             Content: {
  //                 message: f.value.message,
  //             },
  //         };
  //         this.guardarEnHistorial(_historyUser);

  //         const _localAttributes: any = {
  //             site_origin: document.referrer,
  //             domain: document.referrer !== '' ? new URL(document.referrer).host : this.window.location.host,
  //             // site_origin: this.window.location.href,
  //             // domain: this.window.location.host,
  //             // domain: 'www.google.com',
  //         };
  //         const _getSessionAttributes: any = localStorage.getItem('SessionAttributes');
  //         const _createSessionAttributes = _getSessionAttributes !== null ? JSON.parse(localStorage.getItem('SessionAttributes') as string) : _localAttributes;
  //         let stuff: { [key: string]: string } = {};
  //         Object.keys(_createSessionAttributes).forEach((key) => {
  //             stuff[`${key}`] = _createSessionAttributes[key];
  //         });
  //         this.LEX_MESSAGE = {
  //             bot_alias: environment.BOT_ALIAS,
  //             bot_name: environment.BOT_NAME,
  //             inputText: f.value.message,
  //             user_id: localStorage.getItem('Token') as string,
  //             sessionAttributes: stuff,
  //             requestAttributes: {},
  //         };

  //         const _result = (await this.awsLexService.sendLexData(this.LEX_MESSAGE)) as any;
  //         let _response = JSON.parse(_result.body);
  //         _response.sessionAttributes.key_validation_show_models = _response.sessionAttributes.key_validation_show_models ?? 'SHOW_MODELS';
  //         localStorage.setItem('SessionAttributes', JSON.stringify(_response.sessionAttributes, null, 2));

  //         const _historyServer: iHistory = {
  //             type: eTypeMessage.Server,
  //             Content: {
  //                 message: _response.message,
  //             },
  //         };
  //         this.guardarEnHistorial(_historyServer);

  //         this.userResponseForm(f.value.message, _response);
  //         this.scrollDown();
  //         this.DISABLED_INPUT = false;
  //     } else {
  //         alert('Por ahora debe responderme con las opciones que le he brindado.');
  //     }
  //     f.resetForm();
  // }

  /**
   * Obtiene la respuesta proporcionada por el cliente y el servidor para mostrarlo en el contenedor del chat.
   * @param RespuestaUser Texto proporcionado por el usuario.
   * @param RespuestaServer Respuesta devuelta por el servidor.
   * @JuanUicab-Lumston
   */
  userResponseForm(RespuestaUser: string, RespuestaServer: any): void {
    let _divContenedor = this.renderer.createElement('div');
    this.renderer.addClass(_divContenedor, 'botonesRespuesta');

    const _button = this.renderer.createElement('button');
    this.renderer.addClass(_button, 'respuesta');
    const _buttonText = this.renderer.createText(RespuestaUser);
    this.renderer.appendChild(_button, _buttonText);

    this.renderer.appendChild(_divContenedor, _button);
    this.renderer.appendChild(this.MESSAGES.nativeElement, _divContenedor);

    /**Pregunta del server */
    const _buttonResp = this.renderer.createElement('div');
    this.renderer.addClass(_buttonResp, 'question');

    this.separateTextModelDescription(_buttonResp, RespuestaServer.message);
    let _uniqueImg = this.renderer.createElement('img');
    this.renderer.setProperty(_uniqueImg, 'src', this.LOGO_BOT);
    this.renderer.addClass(_uniqueImg, 'botMessage');
    this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
    this.renderer.appendChild(_buttonResp, _uniqueImg);
    this.renderer.appendChild(this.MESSAGES.nativeElement, _buttonResp);
    if (RespuestaServer.responseCard !== undefined && RespuestaServer.responseCard.genericAttachments.length > 0 && RespuestaServer.slotToElicit !== undefined) {
      this.SEND_MESSAGE = false;
      this.POR_ELIMINAR = RespuestaServer.responseCard.genericAttachments.length;
      this.createButtons(RespuestaServer.responseCard.genericAttachments[0].buttons);
    } else {
      this.SEND_MESSAGE = true;
    }
  }

  /**
   * Añade un objeto al historial en el localstorage.
   * @param Registro Objeto para ser almacenado en el historial.
   * @JuanUicab-Lumston
   */
  guardarEnHistorial(Registro: iHistory): void {
    if (localStorage.getItem('History')) {
      const _history: iHistory[] = JSON.parse(localStorage.getItem('History') as string);
      _history.push(Registro);
      localStorage.setItem('History', JSON.stringify(_history));
    } else {
      Registro.Fecha = new Date(Date.now());
      this.HISTORIAL_BOT.push(Registro);
      localStorage.setItem('History', JSON.stringify(this.HISTORIAL_BOT));
    }
  }

  /**
   * Carga el historial de existir en el localstorage.
   * @JuanUicab-Lumston
   */
  cargaHistorial(): void {
    if (localStorage.getItem('History')) {
      this.createHora();
      const _history: iHistory[] = JSON.parse(localStorage.getItem('History') as string);
      const _resta = Math.round((new Date().getTime() - new Date(_history[0].Fecha as Date).getTime()) / (1000 * 60 * 60));
      if (_resta >= 24) {
        this.clearHistory();
        return;
      }

      for (let i: number = 0; i < _history.length; i++) {
        let _div = this.renderer.createElement('div');
        if (_history[i].type === eTypeMessage.Server) {
          this.separateTextModelDescription(_div, _history[i].Content.message);
          this.renderer.addClass(_div, 'question');
          let _uniqueImg = this.renderer.createElement('img');
          this.renderer.setProperty(_uniqueImg, 'src', this.LOGO_BOT);
          this.renderer.addClass(_uniqueImg, 'botMessage');
          this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
          this.renderer.appendChild(_div, _uniqueImg);
          this.renderer.appendChild(this.MESSAGES.nativeElement, _div);
        } else if (_history[i].type === eTypeMessage.User) {
          let _div = this.renderer.createElement('div');
          this.renderer.addClass(_div, 'botonesRespuesta');
          const _button = this.renderer.createElement('button');
          this.renderer.addClass(_button, 'respuesta');
          const _buttonText = this.renderer.createText(_history[i].Content.message);
          this.renderer.appendChild(_button, _buttonText);
          this.renderer.appendChild(_div, _button);
          this.renderer.appendChild(this.MESSAGES.nativeElement, _div);
        } else if (_history[i].type === eTypeMessage.Galery) {
          this.showImages(_div, _history[i].Content.galery);
          this.separateTextModelDescription(_div, _history[i].Content.message);
          this.renderer.addClass(_div, 'question');
          let _uniqueImg = this.renderer.createElement('img');
          this.renderer.setProperty(_uniqueImg, 'src', this.LOGO_BOT);
          this.renderer.addClass(_uniqueImg, 'botMessage');
          this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
          this.renderer.appendChild(_div, _uniqueImg);
          this.renderer.appendChild(this.MESSAGES.nativeElement, _div);
          this.scrollDown();
        }

        if (_history.length - 1 === i) {
          const _buttons = _history[i].Content.message as any[];
          if (Array.isArray(_buttons)) {
            this.POR_ELIMINAR = _buttons.length;
            this.createButtons(_history[i].Content.message);
          } else {
            this.FINISH_ACTIONS = false;
            this.SEND_MESSAGE = true;
          }
        }
      }
    }

    this.PLACEHOLDER = this.SEND_MESSAGE ? 'Escriba aquí...' : 'Seleccione una opción...';
    this.DISABLED_INPUT = !this.SEND_MESSAGE;
    this.scrollDown();
  }

  // deleteAllCookies() {
  //     const _cookies = document.cookie.split(';');

  //     for (var i = 0; i < _cookies.length; i++) {
  //         var cookie = _cookies[i];
  //         var eqPos = cookie.indexOf('=');
  //         var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //         document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  //     }
  // }

  /**
   * Verifica si el botón de envío de datos básicos estará activo o no.
   * @JuanUicab-Lumston
   */
  get getValidForm() {
    return !this.FORM ? true : this.FORM.invalid ? true : false;
  }

  get getAvatar() {
    return this.FORM.controls['avatar'].value;
  }

  contraceptiveMenuBackButton() {
    this.createComponentsAnswerUserWhithoutElement('regreso');
  }

  countryCodeChange() {
    this.flagCode = this.FORM.controls['country'].value.code;
  }

  clearAllMessages() {
    const childElements: any[] = this.MESSAGES.nativeElement.children;
    for (let i: number = childElements.length - 1; i >= 0; i--) {
      this.renderer.removeChild(this.MESSAGES.nativeElement, childElements[i]);
    }
  }

  async mainMenuButton() {
    this.clearAllMessages();
    await this.initChatbot();

    this.createMessageGif(); //ok

    // await this.sendName();
    await this.sendAge();
    await this.sendCountry();
    await this.sendAvatar();

    this.createMessage();
  }

  alertStart(): void {
    this.ALERT_VIEW = false;
    this.STARTER_VIEW = true;
    this.SHOW_BUTTON = false;
    this.LANGUAGE_VIEW = false;
  }

  starterToLang() {
    this.STARTER_VIEW = false;
    this.SHOW_BUTTON = false;
    this.LANGUAGE_VIEW = true;
  }

  starterToConfig() {
    this.STARTER_VIEW = false;
    this.SHOW_BUTTON = true;
  }

  async langToConfig() {
    this.STARTER_VIEW = false;
    this.LANGUAGE_VIEW = false;
    this.SHOW_BUTTON = true;
    this.ALL_COUNTRIES = this.loadCountries();
    await this.initChatbot();
  }

  languageSelection(lang: string) {
    let langArray = [
      {
        language: 'es_419',
        icon: 'logo-header-es.png',
      },
      {
        language: 'en_US',
        icon: 'logo-header-en.png',
      },
      {
        language: 'pt_BR',
        icon: 'logo-header-pr.png',
      },
    ];

    for (const element of langArray) {
      let cardElement = document.getElementById(element.language);

      if (cardElement?.id == lang) {
        this.LOGO_HEADER = `assets/logo/${element.icon}`;
        cardElement?.classList.remove('langCardNoSelected');
        cardElement?.classList.add('langCardSelected');
      } else {
        cardElement?.classList.remove('langCardSelected');
        cardElement?.classList.add('langCardNoSelected');
      }
    }
    this.userLang = lang;
    localStorage.setItem('lang', lang);
    this.translate.setDefaultLang(lang);
  }

  disableHeaderMenuButtons() {
    this.DOTS_VALIDATOR = true;

    let menuButtonElement = document.getElementById('mainMenuButton');
    let contraceptiveButtonElement = document.getElementById('contraceptiveMenuButton');

    menuButtonElement?.classList.remove('mainMenu');
    menuButtonElement?.classList.add('menuButtonDisable');

    contraceptiveButtonElement?.classList.remove('contraceptiveMenu');
    contraceptiveButtonElement?.classList.add('contraceptiveButtonDisable');
  }

  enableHeaderMenuButtons() {
    this.DOTS_VALIDATOR = false;

    let menuButtonElement = document.getElementById('mainMenuButton');
    let contraceptiveButtonElement = document.getElementById('contraceptiveMenuButton');

    menuButtonElement?.classList.add('mainMenu');
    menuButtonElement?.classList.remove('menuButtonDisable');

    contraceptiveButtonElement?.classList.add('contraceptiveMenu');
    contraceptiveButtonElement?.classList.remove('contraceptiveButtonDisable');
  }
}
