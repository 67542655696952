import { HttpRequest, HttpEvent, HttpHeaders, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// Declaramos el interceptor como una función que implementa HttpInterceptorFn
export const tokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn // Aquí usamos HttpHandlerFn en lugar de HttpHandler
): Observable<HttpEvent<unknown>> => {
  // Obtener el valor del idioma y el token desde el almacenamiento local
  const userLang = localStorage.getItem('lang') || 'en'; // Si no hay valor, se asigna un valor por defecto
  const token = localStorage.getItem('token'); // Suponiendo que el token también esté en localStorage

  // Crear una nueva cabecera con las propiedades necesarias
  let headers = new HttpHeaders({
    'x-organon-token': environment.X_ORGANON_TOKEN,
    'x-organon-lang': userLang,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Methods': 'POST'
  });

  // Si el token existe, agregarlo a las cabeceras
  if (token) {
    headers = headers.set('Authorization', `Bearer ${token}`);
  }

  // Clonar la solicitud original con las nuevas cabeceras
  const clonedRequest = request.clone({ headers });

  return next(clonedRequest); // Usamos next(clonedRequest) para pasar la solicitud clonada
};

