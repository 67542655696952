<div id="containerBot" class="botContainer fade" *ngIf="FORM">

  <!--START: THIS IS THE HEADER -->
  <div class="toolbar" role="banner">

      <img *ngIf="!INCHAT_CONTENT" class="img-header" [src]="this.LOGO_HEADER" alt="">

      <div *ngIf="INCHAT_CONTENT" style="display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-right: 23%;
      width: 68%;">
        <button id="mainMenuButton" class="mainMenu" [disabled]="DOTS_VALIDATOR" (click)="mainMenuButton()"> {{'MENU'| translate}} </button>
        <img *ngIf="INCHAT_CONTENT" class="img-header" [src]="this.LOGO_HEADER" alt="">
      </div>

      <div  *ngIf="INCHAT_CONTENT" >
        <button id="contraceptiveMenuButton"  class="contraceptiveMenu" [disabled]="DOTS_VALIDATOR" (click)="contraceptiveMenuBackButton()">
          <span [inlineSVG]="DOTS_VALIDATOR ? 'assets/icons/lista_metodos_gray.svg' : 'assets/icons/lista_metodos.svg'" title="Regresar al menu de métodos anticonceptivos" [cacheSVG]="true" class="float-right"></span>
        </button>
      </div>

  </div>
  <!--END:   THIS IS THE HEADER -->

  <!--START:   THIS IS THE BODY -->
  <div class="content scroll" #botOrganon id="botOrganon">

      <div *ngIf="ALERT_VIEW">
        <div class="content-container-alert">
          <h2 class="content-title-alert">{{ 'ALERT_TITLE' | translate }}</h2>
          <img class="content-image-alert" src="assets/avatars/normal.png" alt="" />
          <p class="content-text-alert">
            {{ 'ALERT_DESCRIPTION_1' | translate }}
          </p>
          <p class="content-text-alert" [innerHTML]="sanitizedDescription"></p>
          <p class="content-text-alert">
            {{ 'ALERT_DESCRIPTION_3' | translate }}
          </p>
        </div>
      </div>

      <div *ngIf="STARTER_VIEW">
        <div class="starterContainer">
          <div style="margin-bottom: 25px;">
            <span class="tituloBienvenida"> {{'WELCOME_2'| translate}} </span>
          </div>

          <div class="starterTextContainer">              
            <div style="padding-bottom: 3px; padding-left: 10px;">
              <span class="leyendaBienvenida">{{'DISCLAIMER_2_1'| translate}}</span>
              <br />
              <span class="leyendaBienvenida">{{'DISCLAIMER_2_2'| translate}}</span>
              <br />
              <span class="leyendaBienvenida">{{'DISCLAIMER_2_3'| translate}}</span>
            </div>
          </div>

        </div>

        <div class="icon-welcome">
          <img src="assets/avatars/logo-welcome.png" alt="">
        </div>

      </div>

      <div *ngIf="LANGUAGE_VIEW">
        <div class="header fade">
            <span class="tituloBienvenida langTitle"> {{'CHOOSE_LANG' | translate}} </span>
        </div>
        <div class="languageCardDiv">
          <mat-card id="es_419" class="langCard langCardSelected" (click)="languageSelection('es_419')">
            <span class="titleSelectLang">¡Bienvenid@!</span>
            <br>
            <span class="langName">Español</span>
          </mat-card>
          <mat-card id="en_US" class="langCard" (click)="languageSelection('en_US')">
            <span class="titleSelectLang">Welcome!</span>
            <br>
            <span class="langName">English</span>
          </mat-card>
          <mat-card id="pt_BR" class="langCard" (click)="languageSelection('pt_BR')">
            <span class="titleSelectLang">Bem-vind@!</span>
            <br>
            <span class="langName">Português</span>
          </mat-card>
        </div>
      </div>

      <div *ngIf="SHOW_BUTTON">
        <div class="header fade">
          <div>
            <span class="tituloBienvenida langTitle"> {{'WELCOME_2'| translate}} </span>
            <br />
            <span class="leyendaBienvenida"> {{'TALK_ABOUT'| translate}} </span>
          </div>

        </div>
        <div class="initForm">
            <form [formGroup]="FORM" class="fade">
                <div class="form-group">
                    <label for="name"> {{'NAME_Q'| translate}}</label>
                    <input name="name" type="text" max="200" formControlName="name" />
                </div>
                <div class="form-group">
                  <label for="age">{{'AGE'| translate}}</label>
                    <div class="mat-select">
                      <mat-select formControlName="age">
                        <mat-option *ngFor="let category of ALL_AGES" [value]="category.value" >
                          {{category.name}}
                        </mat-option>
                      </mat-select>
                    </div>
                </div>
                <div class="form-group">
                    <label for="country">{{'COUNTRY_Q'| translate}}</label>
                      <div class="mat-select">
                        <mat-select class="{{flagCode}}" formControlName="country" (selectionChange)="countryCodeChange()">
                          <mat-option *ngFor="let category of ALL_COUNTRIES" [value]="{value:category.value, code:category.code}" >
                            <img with="20" height="20" [src]="category.image">
                            {{category.name}}
                          </mat-option>
                        </mat-select>
                      </div>
                </div>
                <div class="form-group">
                    <label>{{'AVATAR_SELECTION'| translate}}</label>
                    <br />
                    <div class="avatarContainer">
                        <div *ngFor="let _av of ALL_AVATARS">
                            <img class="item" [ngClass]="{'activeItem': _av.value === getAvatar}" (click)="selectAvatar(_av)" [src]="_av.image" [alt]="_av.value" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
      </div>

  </div>
  <!--END:   THIS IS THE BODY -->

  <!--START: THIS IS THE FOOTER -->
  <div class="containerForm">
      <span *ngIf="INCHAT_CONTENT">{{'SELECT_OPTION'| translate}}</span>
      <button *ngIf="ALERT_VIEW" type="button" class="buttonStarter" (click)="alertStart()">{{'ALERT'| translate}}</button>
      <button *ngIf="STARTER_VIEW" type="button" class="buttonStarter" (click)="starterToLang()">{{'START'| translate}}</button>
      <button *ngIf="LANGUAGE_VIEW" type="button" class="buttonStarter" (click)="langToConfig()">{{'CONTINUE' | translate}}</button>
      <button *ngIf="SHOW_BUTTON" type="button" class="buttonInit" [disabled]="FORM?.invalid" (click)="getBasicData()">{{'START'| translate}}</button>
  </div>
  <!--END:   THIS IS THE FOOTER -->
</div>
